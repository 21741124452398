<!--
 * @Description: 页面导航组件
 * @Autor: WangYuan
 * @Date: 2021-06-10 20:05:23
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-01-27 19:42:09
-->
<template>
  <ul :class="[navStyle == '1' ? 'mainImg' : navStyle == '2' ? 'floatTab' : 'tabs']" :style="getTabsStyle()">
    <li v-for="(item, index) in list" :key="index" class="tabs-item" :class="[item.jump.id == activeTab ? 'f-h5-theme' : '']" @click="changeTab(item)">
      <button v-if="navStyle == '1' && (( list.length == 1 && index == 0 ) || ( list.length == 3 && index == 1 ) || (list.length == 5 && index == 2))" class="tab-bar-main">
        <i class="f28" :class="item.icon" style="z-index: 10;color: #ffffff;"></i>
      </button>
      <i class="f22" :class="item.icon"></i>
      <span class="f13">{{ item.text }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    styles: {
      type: Object,
      default: () => {}
    },
    list: {
      type: Array,
      default: () => []
    },
    active: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    navStyle: {
      type: String,
      default: '0'
    }
  },

  data () {
    return {
      activeTab: ''
    }
  },

  watch: {
    active: {
      immediate: true,
      handler () {
        this.activeTab = this.active
      }
    },

    activeTab: {
      handler () {
        this.$emit('update:active', this.activeTab)
      }
    },
    navStyle: {
      immediate: true,
      handler () {
        // this.activeTab = this.active
      }
    }
  },

  methods: {
    // tabs 动态样式
    getTabsStyle () {
      return {
        // backgroundColor: this.style?.background || '#fff'
        backgroundColor: '#fff'
      }
    },

    // 切换tab
    changeTab (item) {
      if (this.activeTab == item.jump.id) return

      this.activeTab = item.jump.id

      // 设置模式置灰，禁止点击跳转
      if (this.disabled) return

      this.$jump(item.jump)
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs, .mainImg {
  display: flex;
  width: 100%;
  height: 56px;
  border-top: solid 1px #9b9b9b20;
}
.floatTab {
  display: flex;
  width: 92%;
  height: 56px;
  position: absolute;
  bottom: 0;
  left: 4%;
  border-radius: 50px;
}

.tabs-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-size: contain;
  font-size: 10px;
  color: #9b9b9b;

  .tab-bar-main {
    position: absolute;
    bottom: 20px;
    width: 60px;
    height: 62px;
    border: 4px solid #fcfcfc;
    border-radius: 50%;
    background-color: rgb(255, 68, 68);
  }
  .tab-bar-main:focus{outline: none}

  i {
    font-size: 20px;
    margin-bottom: 3px;
  }
}

.tabs-item-select {
  color: #f20c25;
}
</style>
