<!--
 * @Description: 图标选择组件
 * @Autor: WangYuan
 * @Date: 2021-08-23 09:44:05
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-01-27 19:43:50
-->
<template>
  <div class="IconSelect">
    <!-- 选中默认图标 -->
    <div class="flex">
      <div>
        <div class="icon-wrap" @click="selectIcon">
          <i :class="mValue"></i>
        </div>
        <div class="stats">未选中</div>
      </div>

      <!-- 选中高亮图标 -->
      <div style="margin: 0 10px">
        <div class="icon-wrap" @click="selectIcon">
          <i :class="mValue" style="color:#f20c25"></i>
        </div>
        <div class="stats">选中</div>
      </div>
    </div>

    <!-- 选择图标弹窗 -->
    <!-- <el-dialog title="选择图标" :visible.sync="show" width="30%">
      <ul class="flex flex-wrap">
        <li v-for="icon in iconList" :key="icon" class="icon-wrap" :class="[mValue == icon ? 'icon-wrap-active' : '']" style="margin: 5px 10px;" @click="mValue = icon">
          <i class="icon" :class="icon"></i>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
      </span>
    </el-dialog> -->
    <material ref="mater" @iconConfirm="selectItem"></material>
  </div>
</template>

<script>

import material from "@/components/material/index";
export default {
  name: "IconSelect",

  props: ["value"],

  data() {
    return {
      show: false,
      iconList: [
        "icon-shop",
        "icon-cart",
        "icon-goods",
        "icon-my",
        "icon-sort",
        "icon-list",
        "icon-choiceness",
        "icon-crown",
        "icon-hot",
        "icon-daifukuan"
      ],
      mValue: "",
    };
  },
  components:{material},
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.mValue = newValue;
      },
    },

    mValue: {
      immediate: true,
      handler(newValue, oldValue) {
        console.log('newValue-----material',newValue)
        this.$emit("input", newValue);
      },
    },
  },
  mounted() {

  },
  methods: {
    selectIcon(){
      this.$refs.mater.dialogVisible = true;
      this.$refs.mater.activeName = 'second'
    },
    selectItem(val){
      console.log('selectItem---val----',val)
      this.mValue = val;
    }
  }
};
</script>

<style lang="scss" scoped>

@import "../../scss/variable";

.icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border: 1px solid #e3e2e5;
  border-radius: 4px;
  cursor: pointer;

  i {
    color: #7d7d7d;
    font-size: 36px;
  }
}

.stats {
  font-size: 12px;
  text-align: center;
  color: #999999;
  margin-top: 8px;
}

.icon-wrap-active {
  border: 1px solid $color-theme;
}
</style>
